import "../Style/style.css";
import backgroundImage from "../Assets/8.jpg";
import Webcam from "react-webcam";
import axios from "axios";
import { Cloudinary } from "@cloudinary/url-gen";
import { Effect } from "@cloudinary/url-gen/actions/effect";
import { useEffect, useRef, useState } from "react";
import logo from "./crdblogo.png";
import { v4 as uuidv4 } from "uuid";
import regpic from "../Assets/Registration.png";
import { toPng } from "html-to-image";
import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";
import DotLoader from "react-spinners/DotLoader";
import Loaders from "./Loaders.jsx";

let cloudName = "deanyxmez";
const cld = new Cloudinary({
  cloud: {
    cloudName,
  },
});

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

function applyFilter(filter, image) {
  switch (filter) {
    case "artistic":
      return image.effect(Effect.artisticFilter("fes"));
    case "sepia":
      return image.effect(Effect.sepia());
    case "cartoonify":
      return image.effect(Effect.cartoonify());
    case "vignette":
      return image.effect(Effect.vignette());
    case "oilpaint":
      return image.effect(Effect.oilPaint());
    case "grayscale":
      return image.effect(Effect.grayscale());
    case "vectorize":
      return image.effect(Effect.vectorize());
    case "pixelate":
      return image.effect(Effect.pixelate());
    default:
      return image;
  }
}

const filters = [
  "none",
  "artistic",
  "sepia",
  "cartoonify",
  "vignette",
  "oilpaint",
  "grayscale",
  "vectorize",
  "pixelate",
];

function ImagePreviewer({ url, deleteImage, hideCamera }) {
  return url ? (
    <div className="img_box">
      <img src={url} alt="my_image" />
      <div className="button_container">
        <button className="action_btn delete_btn" onClick={deleteImage}>
          Delete
        </button>
        <button className="action_btn continue_btn" onClick={hideCamera}>
          Save
        </button>
      </div>
    </div>
  ) : null;
}

function FilterBox({ imgId, setPrevURL, filterName }) {
  let image = cld.image(imgId);
  image = applyFilter(filterName, image);
  const imgURL = image.toURL();
  return (
    <div className="filter_item" onClick={() => setPrevURL(imgURL)}>
      <img src={imgURL} alt={filterName} />
      <span className="filter_des">{filterName}</span>
    </div>
  );
}

const App = () => {
  const backgroundColor = "#ff4d00";
  const backLinearColor = "#d24102";
  let [color, setColor] = useState("#ffffff");
  let [launch, setLaunch] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("CRDB BMS");
  const [flag, setFlag] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [comments, setComments] = useState("");
  const [photo, setPhoto] = useState(false);
  const [save, setSave] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [continu, setContinue] = useState(true);
  const [names, setNames] = useState("");
  const [personalId, setPersonalId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [statusText, setStatusText] = useState("");
  const [seat, setSeat] = useState("");

  const ref = useRef();

  const handleDownload = () => {
    if (ref.current) {
      toPng(ref.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "registration.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error("Error generating image:", err);
        });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchData = async () => {
        try {
          // console.log("Fetching");
          get(child(dbref, `bmsdata/`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const allposts = snapshot.val();
                // console.log("All data:", allposts);

                if (title) {
                  setLoading(true);
                }

                // Extract employee data from snapshot
                const employees = Object.values(allposts.data);

                // console.log(title);
                // Given Person ID to check
                const givenPersonID = Number(title);

                // Find the employee with the given Person ID
                const employeeData = employees.find(
                  (employee) => employee["id"] === givenPersonID
                );

                const index = employees.findIndex(
                  (employee) => employee["id"] === givenPersonID
                );

                if (index !== -1) {
                  // Adjust index to start from 1
                  const adjustedIndex = index + 1;
                  setComments(adjustedIndex);

                  // Employee data at the found index
                  const employeeData = employees[index];
                } else {
                }

                if (employeeData) {
                  // console.log("Employee name:", employeeData);
                  setNames(employeeData.name);
                  setPersonalId(employeeData.id);
                  setZoneId(employeeData.zone);
                } else {
                }
              } else {
                console.log("No data available");
              }
            })
            .catch((err) => {
              console.error("Error fetching data:", err);
            });
        } catch (err) {
          console.error("Unexpected error:", err);
        }
      };
      fetchData();
    }, 1000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const savingIMG = () => {
    setSave(true);
  };

  const noSave = () => {
    setSave(false);
  };

  const saveing = (e) => {
    // console.log("data: ", title);

    try {
      // console.log("Fetching");
      get(child(dbref, `bmsdata/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const allposts = snapshot.val();
            // console.log("All data:", allposts);

            if (title) {
              setLoading(true);
            }

            // Extract employee data from snapshot
            const employees = Object.values(allposts.data);

            // console.log(title);
            // Given Person ID to check
            const givenPersonID = Number(title);

            // Find the employee with the given Person ID
            const employeeData = employees.find(
              (employee) => employee["id"] === givenPersonID
            );

            const index = employees.findIndex(
              (employee) => employee["id"] === givenPersonID
            );

            if (index !== -1) {
              // Adjust index to start from 1
              const adjustedIndex = index + 1;
              setComments(adjustedIndex);

              // Employee data at the found index
              const employeeData = employees[index];
            } else {
            }

            if (employeeData) {
              // console.log("Employee name:", employeeData);
              setNames(employeeData.name);
              setPersonalId(employeeData.id);
              setZoneId(employeeData.zone);
              setSeat(employeeData.seat);
            } else {
              setStatusText("PF Number not found");
              console.log(
                `Person ID ${givenPersonID} is NOT available in the list.`
              );
            }
          } else {
            console.log("No data available");
          }
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
        });
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  const getFormattedDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const constraints = {
    width: 700,
    height: 550,
    facingMode: "user",
    aspectRatio: 9 / 16,
  };
  const camRef = useRef();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [prevURL, setPrevURL] = useState("");

  const capture = async () => {
    const data = camRef.current.getScreenshot();
    try {
      setLoading(true);
      const imageData = new FormData();
      imageData.append("file", data);
      imageData.append("upload_preset", "ackesselfie");
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        imageData
      );
      const imageDetails = res.data;
      setId(imageDetails.public_id);
      setPrevURL(imageDetails.url);
      setImageUrl(res.data.url);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModel = () => {
    setFlag(false);
  };

  const submit = (e) => {
    setLaunch(true);
    setContinue(false);
    //   window.location.reload();
  };
  const getInfos = () => {};

  const hideCamera = () => {
    // console.log("data:", title, names, personalId, zoneId, imageUrl);
    setLaunch(false);
    setSave(true);
    setCaptured(false);
    setContinue(true);

    if (title && names && personalId && zoneId && imageUrl) {
      update(ref(db, `bmsdata/${generateUUID()}`), {
        name: names,
        title: personalId,
        phone: zoneId,
        comments: comments,
        imageUrl: imageUrl,
        datetime: getFormattedDate(),
      }).then(() => {});

      update(ref(db, `bms/recent`), {
        name: names,
        title: personalId,
        phone: zoneId,
        comments: seat,
        imageUrl: imageUrl,
        datetime: getFormattedDate(),
      }).then(() => {});
      setStatus("SUCCESSFULLY!");
    } else {
      setPhoto(true);
      setStatus("Fill All Details");
      setFlag(true);

      setLoadings(false);
    }
    setLoadings(false);
  };

  const deleteImage = () => {
    setImageUrl("");
    setId("");
    setStatus("Image Deleted, Capture another Image!");
    window.location.reload();
  };

  function generateUUID() {
    return uuidv4();
  }

  useEffect(() => {
    return () => {};
  }, []);

  const reloadWindow = () => {
    window.location.reload();
  };

  const formatData = () => {
    return `
------------------------------
CRDB AIRWAYS CONFERENCE
------------------------------
PF NUMBER   : ${personalId}
------------------------------
NAME        : ${names}
------------------------------
ZONE        : ${zoneId}
------------------------------
SEAT NO.    : ${seat}
------------------------------
    `;
  };

  const downloadData = () => {
    const formattedData = formatData();
    const blob = new Blob([formattedData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `CRDB_BMS_DETAILS.txt`;
    link.click();

    URL.revokeObjectURL(url); // Clean up the object URL
  };

  return (
    <>
      {launch && (
        <section className="main">
          <article className="media_box">
            <div className="camera">
              <Webcam
                ref={camRef}
                className="webcam"
                videoConstraints={constraints}
                screenshotFormat="image/jpeg"
              />
              {!prevURL && (
                <div
                  disabled={loading}
                  onClick={capture}
                  className="capture_btn"
                >
                  {/* 📸 */}
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    src={logo}
                  />
                </div>
              )}
            </div>

            <ImagePreviewer
              url={prevURL}
              deleteImage={deleteImage}
              hideCamera={hideCamera}
            />
          </article>

          {!prevURL && (
            <article className="filter_container">
              <div className="filter_scroll">
                {id &&
                  filters.map((filter, index) => (
                    <FilterBox
                      imgId={id}
                      filterName={filter}
                      setPrevURL={setPrevURL}
                      key={index}
                    />
                  ))}
              </div>
            </article>
          )}
        </section>
      )}

      {continu && (
        <div className="container">
          {names === "" && (
            <div className="mainbody">
              <div className="profileImage">
                <img
                  style={{
                    height: "70px",
                    weight: "70px",
                  }}
                  src={logo}
                />
              </div>

              <div className="note">
                <p>{status} </p>
              </div>

              {flag && (
                <div className="staaa">
                  <p>{status}</p>{" "}
                  {photo ? (
                    <div className="confi">
                      <div
                        className="star"
                        style={{
                          backgroundColor: "red",
                        }}
                        onClick={reloadWindow}
                      >
                        No
                      </div>

                      <div className="star" onClick={closeModel}>
                        Yes
                      </div>
                    </div>
                  ) : (
                    <div className="star" onClick={closeModel}>
                      Okay
                    </div>
                  )}
                </div>
              )}

              <div className="inputsect">
                <div className="inputpoints">
                  <label>PF NUMBER</label>{" "}
                  <input
                    type="text"
                    name="title"
                    value={title}
                    placeholder="Enter your PF Number"
                    className="mt-1 block w-full"
                    autoComplete="email"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>

                {statusText != "" && (
                  <div
                    style={{
                      margin: "23px",
                      color: "white",
                      fontSize: "12px",
                      backgroundColor: "#00800020",
                      padding: "12px",
                      borderRadius: "4px",
                      border: "1px solid #ffffff20",
                    }}
                  >
                    {statusText}
                  </div>
                )}

                {loadings ? (
                  <div className="spin">
                    {" "}
                    <DotLoader
                      color={color}
                      loading={loadings}
                      // cssOverride={override}
                      size={25}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div className="logbtn" onClick={saveing}>
                  Submit
                </div>

                <div className="lastquote">
                  <p>crdb-bms@2024</p>
                </div>
              </div>
            </div>
          )}

          {names != "" && (
            <div className="mainbody">
              <div className="profileImage">
                <img
                  style={{
                    height: "70px",
                    weight: "70px",
                  }}
                  src={logo}
                />
              </div>

              {save && (
                <div className="regist">
                  <div ref={ref} className="regpic">
                    <img src={regpic} alt="" />
                    <p className="nameee">{names}</p>
                    <p className="namee">{seat}</p>
                    <p className="namse">{zoneId}</p>
                    <img
                      className="namsed"
                      style={{
                        height: "90px",
                        width: "90px",
                        borderRadius: "50%",
                      }}
                      src={imageUrl}
                      alt=""
                    />
                  </div>
                </div>
              )}

              {save && (
                <button className="download" onClick={handleDownload}>
                  Download
                </button>
              )}

              <div className="note">
                <p>CRDB BMS </p>
              </div>

              {flag && (
                <div className="staaa">
                  <p>{status}</p>{" "}
                  {photo ? (
                    <div className="confi">
                      <div
                        className="star"
                        style={{
                          backgroundColor: "red",
                        }}
                        onClick={reloadWindow}
                      >
                        No
                      </div>

                      <div className="star" onClick={closeModel}>
                        Yes
                      </div>
                    </div>
                  ) : (
                    <div className="star" onClick={closeModel}>
                      Okay
                    </div>
                  )}
                </div>
              )}

              <div className="inputsect">
                <div className="inputpointss">
                  <label>PF NUMBER</label>
                  <label>{personalId}</label>
                </div>
                <div className="inputpointss">
                  <label>NAME</label>
                  <label>{names}</label>
                </div>
                <div className="inputpointss">
                  <label>ZONE</label>
                  <label>{zoneId}</label>
                </div>

                <div className="inputpointss">
                  <label>SEAT N0.</label>
                  <label>{seat}</label>
                </div>

                <div className="lines"></div>

                {loadings ? (
                  <div className="spin">
                    {" "}
                    <DotLoader
                      color={color}
                      loading={loadings}
                      // cssOverride={override}
                      size={25}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div className="logbtn" onClick={submit}>
                  Take A Photo
                </div>

                <div className="lastquote">
                  <p>crdb-bms@2024</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default App;
