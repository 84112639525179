// src/ExcelUploader.js
import React, { useState } from "react";
import * as XLSX from "xlsx";

import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

const Uploader = () => {
  const [excelData, setExcelData] = useState([]);
  const [uploadDate, setUploadDate] = useState("");
  const [category, setCategory] = useState("Contributions");
  const [referenceAmount, setReferenceAmount] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      setExcelData(sheetData);
      setUploadDate(new Date().toISOString().split("T")[0]);
    };

    reader.readAsBinaryString(file);
  };

  const handleUploadToFirebase = () => {
    if (excelData.length === 0) {
      alert("No data to upload!");
      return;
    }

    const uniqueKey = `bmsdata_${Date.now()}`;
    const sanitizedData = excelData.map((row) => {
      const sanitizedRow = {};
      Object.entries(row).forEach(([key, value]) => {
        const sanitizedKey = key.replace(/[./#$\[\]]/g, "");
        sanitizedRow[sanitizedKey] = value;
      });
      return sanitizedRow;
    });

    const dataRef = ref(database, `bmsdata/`);
    set(dataRef, {
      date: uploadDate,
      referenceAmount: referenceAmount || null,
      data: sanitizedData,
    })
      .then(() => alert("Data uploaded successfully!"))
      .catch((err) => console.error("Error uploading data:", err));
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Upload Excel Data to Firebase</h1>

      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      <button
        onClick={handleUploadToFirebase}
        disabled={excelData.length === 0}
      >
        Upload to Firebase
      </button>

      {excelData.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <h3>Preview Data:</h3>
          <table
            border="1"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <thead>
              <tr>
                {Object.keys(excelData[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {excelData.map((row, idx) => (
                <tr key={idx}>
                  {Object.values(row).map((value, i) => (
                    <td key={i}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Uploader;
